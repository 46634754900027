import Tools from './tools';

export default class Nav {
    constructor() {
        // Nav

        this.burger();
        this.subItems();
    }

    burger() {
        const btn = document.querySelector('.nav__burger');
        const nav = document.querySelector('.nav');

        if(btn && nav) {
            btn.addEventListener('click', () => {
                nav.classList.toggle('js-open');
            });
        }
    }

    subItems() {
        const elts = document.querySelectorAll('.nav__listLink');
        if(elts.length > 0) {
            elts.forEach((elt) => {
                if(elt.tagName == 'SPAN') {
                    elt.addEventListener('click', (e) => {
                        e.preventDefault();
                        if(document.querySelector('.nav__listItem.js-open') && document.querySelector('.nav__listItem.js-open') != elt.parentElement) {
                            document.querySelector('.nav__listItem.js-open').classList.remove('js-open');
                        }
                        elt.parentElement.classList.toggle('js-open');
                    });
                } else {
                    elt.addEventListener('click', () => {
                        document.querySelector('.nav').classList.remove('js-open');
                    });
                }
            })
        }

        const links = document.querySelectorAll('.nav__listSubLink');
        if(links.length > 0) {
            links.forEach((link) => {
                link.addEventListener('click', () => {
                    document.querySelector('.nav').classList.remove('js-open');
                });
            });
        }
    }
}