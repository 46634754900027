import gsap from 'gsap';

export default class Tools {
  static lazy() {
    const images = document.querySelectorAll('img[data-src]');
    images.forEach( e => {
      if(!e.classList.contains('lazy')){
          e.src = e.dataset.src;
      }
    });
  }

  static offset(el) {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top + window.pageYOffset,
      bottom: rect.bottom + window.pageYOffset,
      left: rect.left + window.pageXOffset,
      right: rect.right + window.pageXOffset,
    };
  }
  
  static customInputFile() {
    const formInputs = document.querySelectorAll('input[type="file"]');
    formInputs.forEach( item => {
      item.addEventListener('change', this.editCustomInputFileName);
    });   
  }
  
  static editCustomInputFileName(input) {
    if (input.currentTarget.dataset.maxsize < input.currentTarget.files[0].size) {
      alert('Le fichier est trop volumineux');
      input.currentTarget.value = '';
    } else {
      input.currentTarget.nextElementSibling.innerHTML = input.currentTarget.files[0].name;
    }
  }

  static string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  static innerImageParallax(imageContainer, decalage = 20) {
    const image = imageContainer.querySelector('.media, .image');

    const sizeIncrement = Math.abs(decalage);

    if (!imageContainer || !image) {
      console.error('No image or imageContainer to animate', imageContainer);
      return false;
    }

    const imageTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: imageContainer,
        scrub: true,
      },
    });

    gsap.set(image, {
      height: `${100 + sizeIncrement}%`,
    });

    gsap.set(imageContainer, {
      overflow: 'hidden',
    });

    let from = `${-decalage}%`;
    let to = '0%';
    if (decalage < 0) {
      from = '0%';
      to = `${decalage}%`;
    }

    imageTimeline
      .fromTo(image, {
        y: from,
      }, {
        y: to,
        ease: 'linear',
        duration: 1,
      });

    return imageTimeline;
  }

  static fromAuto($element, height, duration = 0.85, ease = 'power4.inOut') {
    $element.style.height = 'auto';
    const autoHeight = $element.offsetHeight;

    return gsap.fromTo($element, {
      height: autoHeight,
    }, {
      height,
      ease,
      duration,
    });
  }

  static autoToZero($element, duration = 0.85, ease = 'power4.inOut') {
    return this.fromAuto($element, 0, duration, ease);
  }

  static zeroToAuto($element, duration = 0.85, ease = 'power4.inOut') {
    const curHeight = $element.offsetHeight;
    $element.style.height = 'auto';
    const autoHeight = $element.offsetHeight;
    $element.style.height = curHeight;

    return gsap.fromTo($element, {
      height: 0,
    }, {
      height: autoHeight,
      ease,
      duration,
      onComplete: () => {
        $element.style.height = 'auto';
      },
    });
  }
}